<template>
  <v-row>
    <v-col class="pt-3 pt-sm-6 pt-lg-9" cols="12" lg="6">
      <!-- files -->
      <form-deposit-screens
        v-if="contentType === 'file'"
        :content="newFiles"
        :error-messages="feedback('content')"
        :files="oldFiles"
        @input="changeFiles"
        @remove-file="removeFile"
        @remove-content-image="removeImage"
      />

      <!-- textarea content -->
      <v-textarea
        v-if="contentType === 'code'"
        :disabled="disabled"
        :error-messages="feedback('content')"
        :label="$t('Deposit content')"
        :loading="disabled"
        :rows="14"
        :rules="rules.contentText"
        :value="deposit.content"
        auto-grow
        class="deposit-content--code"
        outlined
        @input="input('content', $event)"
      />

      <!-- design preview — not needed here? -->
      <div
        v-if="deposit.type === 'design'"
        class="deposit-content--design-preview"
      >
        <img v-if="deposit.type === 'design'" :src="deposit.preview" alt="">
      </div>
    </v-col>

    <v-col class="pt-lg-9" cols="12" lg="6">
      <!-- input title -->
      <v-row>
        <v-col>
          <v-text-field
            ref="title"
            :counter="limits.name"
            :disabled="disabled"
            :error-messages="feedback('name')"
            :hint="$t('Deposit title in {{count}} characters', {count: limits.name})"
            :label="$t('Title') + ' *'"
            :loading="disabled"
            :rules="rules.name"
            :value="deposit.name"
            outlined
            @input="input('name', $event)"
          />
        </v-col>
      </v-row>

      <!-- version -->
      <v-row>
        <v-col>
          <v-text-field
            :disabled="disabled"
            :error-messages="feedback('version')"
            :hint="$t('Version of implementation')"
            :label="$t('Version')"
            :loading="disabled"
            :rules="rules.version"
            :value="deposit.version"
            outlined
            @input="input('version', $event)"
          />
        </v-col>
      </v-row>

      <!-- selector project -->
      <v-row align-content="start">
        <v-col>
          <v-select
            :disabled="disabled"
            :error-messages="feedback('project.id')"
            :hint="allowCreateProject ? $t('Select existing project or create new one') : null"
            :items="projects"
            :label="$t('Project')"
            :loading="disabled || project.loading || project.saving"
            :rules="rules.project"
            :value="deposit.project ? deposit.project.id : null"
            clearable
            item-text="name"
            item-value="id"
            menu-props="offset-y"
            outlined
            persistent-hint
            @input="changeProject"
          >
            <template v-slot:no-data>
              <div class="pa-4 text--disabled">
                {{ $t('No projects present') }}
              </div>
            </template>
          </v-select>
        </v-col>
        <v-col v-if="allowCreateProject" cols sm="auto">
          <project-dialog-add-edit @create="input('project', $event)" />
        </v-col>
      </v-row>

      <!-- textarea description -->
      <v-row>
        <v-col>
          <v-textarea
            :counter="limits.description"
            :disabled="disabled"
            :error-messages="feedback('description')"
            :hint="$t('Short description of deposit')"
            :label="$t('Description')"
            :loading="disabled"
            :rows="3"
            :rules="rules.description"
            :value="deposit.description"
            auto-grow
            outlined
            @input="input('description', $event)"
          />
        </v-col>
      </v-row>

      <!-- input tags -->
      <v-row>
        <v-col>
          <v-combobox
            :append-icon="''"
            :disabled="disabled"
            :error-messages="feedback('tags')"
            :hint="$t('Enter tags for deposit')"
            :items="tags"
            :label="$t('Tags')"
            :loading="disabled"
            :value="deposit.tags"
            chips
            deletable-chips
            height="56"
            multiple
            outlined
            @focus="tagsFocus"
            @input="input('tags', $event)"
          />
        </v-col>
      </v-row>

      <!-- programming languages -->
      <v-row>
        <v-col>
          <v-select
            :disabled="disabled"
            :error-messages="feedback('language')"
            :items="langItems"
            :label="$t('Programming language')"
            :value="deposit.language"
            chips
            deletable-chips
            multiple
            outlined
            @input="input('language', $event)"
          />
        </v-col>
      </v-row>

      <!-- os name and version -->
      <v-row>
        <v-col>
          <v-text-field
            :disabled="disabled"
            :error-messages="feedback('os_name')"
            :hint="$t('Name of the operating system in which implementation can be used')"
            :label="$t('OS name')"
            :loading="disabled"
            :rules="rules.os_name"
            :value="deposit.os_name"
            outlined
            @input="input('os_name', $event)"
          />
        </v-col>
        <v-col>
          <v-text-field
            :disabled="disabled"
            :error-messages="feedback('os_version')"
            :hint="$t('Version of the operating system in which implementation can be used')"
            :label="$t('OS version')"
            :loading="disabled"
            :rules="rules.os_version"
            :value="deposit.os_version"
            outlined
            @input="input('os_version', $event)"
          />
        </v-col>
      </v-row>

      <!-- actors -->
      <form-deposit-actors
        v-for="(title, rights) in authorsRights"
        :key="`actors-${rights}`"
        :actors="filterActors(rights)"
        :contribution-weight-mode="contributionWeightModes[rights]"
        :disabled="disabled"
        :disabled-add-teammate="deposit.project === null"
        :rights="rights"
        :server-feedback="actorsServerFeedback"
        @change-actors="changeActors(rights, $event)"
        @change-contribution-weight-mode="changeContributionWeightMode(rights, $event)"
      />
    </v-col>
  </v-row>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import {cloneDeep} from 'lodash'
import {mdiPlus} from '@mdi/js'
import FormDepositActors from '@/components/FormDepositActors'
import FormDepositScreens from '@/components/FormDepositScreens'
import ProjectDialogAddEdit from '@/components/ProjectDialogAddEdit'
import error401handler from '@/mixins/error-401-handler'
import {today} from '@/util/dates'
import {convertDecimalToFraction, isFraction, isPercent} from '@/util/util'

const newAuthor = {
  rights: 'author',
  contributionWeight: null,
  territory: null,
  dateFrom: today()
}
const defaultTerritory = '00'

export default {
  name: 'FormDeposit',
  components: {
    FormDepositActors,
    FormDepositScreens,
    ProjectDialogAddEdit
  },
  mixins: [error401handler],
  props: {
    value: {type: Object, required: true},
    depositType: {type: Object, required: true},
    serverFeedback: {type: Object, required: true},
    disabled: {type: Boolean, default: false},
    saveButtonLabel: {type: String, default: 'Save'}
  },
  data () {
    return {
      contributionWeightModes: {},
      project: {
        name: '',
        dialog: false,
        loading: false,
        saving: false,
        serverFeedback: null
      },
      oldFiles: [],
      newFiles: [],
      rules: {
        name: [
          v => !!v || this.$t('Deposit title is required'),
          v => v?.length <= this.limits.name || this.$t(
            'Deposit title must be less than {{count}} characters',
            {count: this.limits.name}
          )
        ],
        version: [
          v => !v || v.length <= this.limits.version || this.$t(
            'Version must be less than {{count}} characters',
            {count: this.limits.version}
          )
        ],
        description: [
          v => !v || v.length <= this.limits.description || this.$t(
            'Description must be less than {{count}} characters',
            {count: this.limits.description}
          )
        ],
        contentText: [
          v => !v || v.length <= this.limits.contentText || this.$t(
            'Content length must be less than {{count}} characters',
            {count: this.limits.contentText}
          )
        ],
        project: [
          v => !!v || this.$t('Choose a project')
        ],
        os_name: [
          v => !v || v.length <= this.limits.os_name || this.$t(
            'OS name must be less than {{count}} characters',
            {count: this.limits.os_name}
          )
        ],
        os_version: [
          v => !v || v.length <= this.limits.os_version || this.$t(
            'OS version must be less than {{count}} characters',
            {count: this.limits.os_version}
          )
        ],
      },
      icons: {
        plus: mdiPlus
      }
    }
  },
  computed: {
    ...mapState({
      isBusinessAccount: state => state.profile.accountType === 'business',
      profileLoaded: state => state.loaders.profile.status,
      profile: state => state.profile,
      languages: state => state.config.deposit.types.implementation.languages,
      // types of actors
      actorsRights: state => state.config.deposit.actors.rights,

      projectsLoadedPrv: state => state.projects?.projectsLoaded,
      projectsLoadedBiz: state => state.projectsBusiness?.projectsLoaded,
      limitsPrv: state => state.deposits?.limits,
      limitsBiz: state => state.depositsBusiness?.limits,
      projectsPrv: state => state.projects?.projects,
      projectsBiz: state => state.projectsBusiness?.projects,
      tagsPrv: state => state.deposits?.tags,
      tagsBiz: state => state.depositsBusiness?.tags,
      tagsLoadedPrv: state => state.deposits?.tagsLoaded,
      tagsLoadedBiz: state => state.depositsBusiness?.tagsLoaded,
    }),
    // TODO refactor — now copy-pasted Prv vs Biz with adapters
    projectsLoaded () {
      return this.isBusinessAccount ? this.projectsLoadedBiz : this.projectsLoadedPrv
    },
    limits () {
      return this.isBusinessAccount ? this.limitsBiz : this.limitsPrv
    },
    projects () {
      return this.isBusinessAccount ? this.projectsBiz : this.projectsPrv
    },
    tags () {
      return this.isBusinessAccount ? this.tagsBiz : this.tagsPrv
    },
    tagsLoaded () {
      return this.isBusinessAccount ? this.tagsLoadedBiz : this.tagsLoadedPrv
    },
    ...mapGetters({
      getProjectPrvById: 'projects/getProjectById',
      getProjectBizById: 'projectsBusiness/getProjectById',
    }),
    getProjectById () {
      return this.isBusinessAccount ? this.getProjectBizById : this.getProjectPrvById
    },
    deposit () {
      return this.value
    },
    contentType () {
      return this.depositType.content_type
    },
    actorsServerFeedback () {
      let feedback = {}
      Object.keys(this.serverFeedback)
        .filter(key => key.startsWith('actors'))
        .forEach(key => {
          feedback[key] = this.serverFeedback[key]
        })
      return feedback
    },
    allowCreateProject () {
      return !this.profile.accountType
    },
    authorsRights () {
      let {author} = this.actorsRights
      return {author}
    },
    langItems () {
      return Object.entries(this.languages).map(([value, text]) => ({value, text}))
    },
  },
  watch: {
    profileLoaded (val) {
      if (val) this.initialLoad()
    }
  },
  async mounted () {
    Object.keys(this.actorsRights).forEach(rights => {
      let cwString = this.filterActors(rights)[0]?.contributionWeight
      this.$set(
        this.contributionWeightModes,
        rights,
        isFraction(cwString) ? 'fraction' : 'percent'
      )
    })

    if (this.contentType === 'file' && this.deposit.files?.length) {
      this.oldFiles = cloneDeep(this.deposit.files)
    }
    if (this.profileLoaded) await this.initialLoad()

    this.$nextTick(() => {
      let inputTitle = this.$refs.title.$el.querySelector('input')
      if (inputTitle) {
        setTimeout(() => {
          inputTitle.focus()
        }, 0)
      }
    })
  },
  methods: {
    async initialLoad () {
      if (!this.projectsLoaded) {
        try {
          await this.loadProjects()
        } catch (e) {
          this.handleError(e, {
            mode: 'modal',
            title: this.$t('Unable load projects')
          })
        }
      }
    },
    ...mapActions({
      loadProjectsPrv: 'projects/prLoad',
      loadProjectsBiz: 'projectsBusiness/prLoad',
      loadTagsPrv: 'deposits/dpLoadTags',
      loadTagsBiz: 'depositsBusiness/dpLoadTags',
    }),
    // TODO refactor — now copy-pasted Prv vs Biz with adapters
    loadProjects () {
      return (this.isBusinessAccount ? this.loadProjectsBiz : this.loadProjectsPrv)(...arguments)
    },
    loadTags () {
      return (this.isBusinessAccount ? this.loadTagsBiz : this.loadTagsPrv)(...arguments)
    },
    filterActors (rights) {
      return this.deposit.actors?.filter(a => a.rights === rights) || []
    },
    feedback (field) {
      if (Array.isArray(this.serverFeedback[field])) {
        return this.serverFeedback[field].join(' ')
      }
      return this.serverFeedback[field] || ''
    },
    input (field, value) {
      let deposit = cloneDeep(this.deposit)
      // TODO оставил пустые if'ы для мержа с веткой аплоада исходников одним архивом, потом убрать

      if (field === 'content' && this.contentType === 'file') {

      } else if (field === 'project') {

      } else if (field === 'actors') {

      } else {
        deposit[field] = value
      }
      this.$emit('input', deposit)
    },

    changeFiles (value) {
      let deposit = cloneDeep(this.deposit)

      this.newFiles.push(...value)
      deposit.content = [...this.newFiles]

      this.$emit('input', deposit)
    },
    changeProject (value) {
      let deposit = cloneDeep(this.deposit)

      let project = this.getProjectById(value)
      deposit.project = project || null

      if (project?.teammates?.length > 0) {
        let cwString = project.teammates[0].user.contributionWeight
        this.contributionWeightModes.author = /^\d+\/\d+$/.test(cwString)
          ? 'fraction'
          : 'percent'

        deposit.actors = project.teammates.map(tm => ({
          ...newAuthor,
          name: `${tm.user.first_name} ${tm.user.last_name}`,
          account_id: tm.account_id,
          territory: [defaultTerritory],
          contributionWeight: tm.user.contributionWeight,
        }))
      } else {
        deposit.actors = []
      }

      this.$emit('input', deposit)
    },
    changeActors (rights, actors) {
      if (this.deposit.actors?.length !== actors.length) {
        // recalculate contributions
        let cwMode = ''
        if (actors.length > 1) {
          cwMode = (100 / actors.length) % 1
            ? 'fraction'
            : 'percent'

          actors.forEach((actor, i) => {
            actors[i].contributionWeight = cwMode === 'percent'
              ? (1 / actors.length).toString()
              : `1/${actors.length}`
          })
        } else if (actors.length === 1) {
          actors[0].contributionWeight = '1.0'
        }
        this.contributionWeightModes[rights] = cwMode
      }

      this.emitActors(rights, actors)
    },
    changeContributionWeightMode (rights, cwMode) {
      this.contributionWeightModes[rights] = cwMode

      let actors = cloneDeep(this.filterActors(rights))
      actors.forEach((actor, _, arr) => {
        if (cwMode === 'percent' && isFraction(actor.contributionWeight)) {
          let [num, den] = actor.contributionWeight.split('/')
          actor.contributionWeight = (num / den).toString().substring(0, 4)
        } else if (cwMode === 'fraction' && isPercent(actor.contributionWeight)) {
          actor.contributionWeight =
            convertDecimalToFraction(actor.contributionWeight, arr.length)
        }
      })

      this.emitActors(rights, actors)
      this.$emit('change-contribution-weight-mode')
    },
    emitActors (rights, actors) {
      let deposit = cloneDeep(this.deposit)
      deposit.actors = deposit.actors
        ? deposit.actors.filter(a => a.rights !== rights).concat(actors)
        : actors
      this.$emit('input', deposit)
    },

    removeFile (index) {
      this.$set(this.oldFiles[index], 'deleted', true)
      let deposit = cloneDeep(this.deposit)
      deposit.files = [...this.oldFiles]
      this.$emit('input', deposit)
    },
    removeImage (index) {
      this.newFiles.splice(index, 1)
      let deposit = cloneDeep(this.deposit)
      deposit.content = [...this.newFiles]
      this.$emit('input', deposit)
    },
    async tagsFocus () {
      if (!this.tagsLoaded) {
        await this.loadTags()
      }
    }
  }
}
</script>
