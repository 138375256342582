<template>
  <div>
    <v-row>
      <v-col>
        <div class="text-caption mb-2 px-4">
          {{ actorsTitle }}
          <v-chip v-if="actors.length > 1" small>
            {{ actors.length }}
          </v-chip>
        </div>
        <div class="mb-2">
          <form-deposit-add-teammate
            v-if="rights === 'author' && profile.accountType === 'business'"
            :authors="actors"
            :disabled="disabled || disabledAddTeammate"
            @add="addTeammate"
          />
          <v-btn
            v-else
            :disabled="disabled"
            :ripple="false"
            color="secondary"
            outlined
            rounded
            small
            @click="addActor('individual')"
          >
            {{ addActorButtonTitle }}
          </v-btn>
        </div>
      </v-col>
      <v-col v-if="actors.length > 1" cols="auto">
        <form-contribution-weight-mode
          :value="contributionWeightMode"
          @change="$emit('change-contribution-weight-mode', $event)"
        />
      </v-col>
    </v-row>

    <transition-group name="actors" tag="div" class="actors-transition">
      <template v-for="(actor, i) in actors">
        <form-deposit-actor-individual
          v-if="actor"
          :key="`actor-${i}`"
          :actor="actor"
          :cw-mode="contributionWeightMode"
          :disabled="disabled"
          :server-feedback="feedback(i)"
          class="mb-5"
          @input="input(actor.account_id, $event)"
          @remove="removeActor(actor.account_id)"
        />
      </template>
    </transition-group>

    <transition name="fade">
      <progress-bar-contribution
        v-if="actors.length > 1"
        :actors="actors"
        :cw-mode="contributionWeightMode"
        class="mb-3 fractions-progress-bar"
      />
    </transition>

    <v-row>
      <v-col>
        <p v-show="allActorsFeedback" class="error--text text-caption">
          {{ allActorsFeedback }}
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {cloneDeep} from 'lodash'
import ProgressBarContribution from '@/components/ProgressBarContribution'
import FormContributionWeightMode from '@/components/FormContributionWeightMode'
import FormDepositActorIndividual from '@/components/FormDepositActorIndividual'
import FormDepositAddTeammate from '@/components/FormDepositAddTeammate'
import {today} from '@/util/dates'

const newActor = {
  name: null,
  rights: null,
  status: null,
  contributionWeight: null,
  territory: ['00'],
  dateFrom: today()
}

export default {
  name: 'FormDepositActors',
  components: {
    FormContributionWeightMode,
    FormDepositAddTeammate,
    FormDepositActorIndividual,
    ProgressBarContribution
  },
  props: {
    actors: {type: Array, required: true},
    contributionWeightMode: {type: String, default: ''},
    disabled: {type: Boolean, default: false},
    disabledAddTeammate: {type: Boolean, default: false},
    rights: {type: String, required: true},
    serverFeedback: {type: Object, required: true}
  },
  data () {
    return {
      allActorsFeedback: ''
    }
  },
  computed: {
    ...mapState({
      profile: state => state.profile,
      defaultAuthorName: state => state.profile.name
    }),
    actorsTitle () {
      let title = this.$t('Actor', {count: this.actors.length})
      if (this.rights === 'author') {
        title = this.$t('Author', {count: this.actors.length})
      }
      if (this.rights === 'rightsholder') {
        title = this.$t('Rightsholder', {count: this.actors.length})
      }
      return title
    },
    addActorButtonTitle () {
      let title = this.$t('Add actor')
      if (this.rights === 'author') {
        title = this.$t('Add author')
      }
      if (this.rights === 'rightsholder') {
        title = this.$t('Add rightsholder')
      }
      return title
    },
  },
  watch: {
    serverFeedback (newVal) {
      this.allActorsFeedback = this.message(newVal.actors)
    },
  },
  methods: {
    message (value) {
      if (Array.isArray(value)) return value.join(' ')
      return value || ''
    },
    feedback (actorIndex) {
      let feedback = {}
      Object.keys(this.serverFeedback)
        .filter(key => key.startsWith(`actors.${actorIndex}.`))
        .forEach(key => {
          feedback[key.split('.')[2]] = this.serverFeedback[key]
        })
      return feedback
    },
    input (accountId, actor) {
      this.allActorsFeedback = ''
      let actors = cloneDeep(this.actors)
      let i = actors.findIndex(a => a.account_id === accountId)
      if (i !== -1) {
        actors[i] = actor
        this.$emit('change-actors', actors)
      }
    },
    addActor (personType) {
      this.allActorsFeedback = ''
      let actors = cloneDeep(this.actors)
      let actor = cloneDeep(newActor)
      actor.rights = this.rights
      actor.status = personType

      if (!actors.length) {
        actor.name = this.defaultAuthorName
      }

      actors.push(actor)
      this.$emit('change-actors', actors)
    },
    addTeammate (author) {
      this.allActorsFeedback = ''
      let actors = cloneDeep(this.actors)
      actors.push(author)
      this.$emit('change-actors', actors)
    },
    removeActor (accountId) {
      this.allActorsFeedback = ''
      let actors = cloneDeep(this.actors)
      let i = actors.findIndex(a => a.account_id === accountId)
      if (i !== -1) {
        actors.splice(i, 1)
        this.$emit('change-actors', actors)
      }
    },
  }
}
</script>
