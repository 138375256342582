<template>
  <v-menu bottom offset-y>
    <template #activator="{on, attrs}">
      <v-btn
        :disabled="disabled || teammatesToAdd.length === 0"
        :ripple="false"
        color="secondary"
        outlined
        rounded
        small
        v-bind="attrs"
        v-on="on"
      >
        {{ $t('Add teammate') }}
      </v-btn>
      <div v-show="disabled" class="caption text--secondary px-4 mt-2">
        {{ $t('To add teammates you have to select project first') }}
      </div>
    </template>

    <v-list>
      <v-list-item
        v-for="tm in teammatesToAdd"
        :key="tm.user.pds_id"
        :ripple="false"
        @click="addAuthor(tm)"
      >
        <v-list-item-icon>
          <app-avatar :image="tm.user.avatar" size="32" />
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ tm.user.first_name }}
            {{ tm.user.last_name }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ tm.user.email }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import {mapState} from 'vuex'
import AppAvatar from '@/components/AppAvatar'
import {today} from '@/util/dates'

const newAuthor = {
  rights: 'author',
  contributionWeight: null,
  territory: null,
  dateFrom: today()
}
const defaultTerritory = '00'

export default {
  name: 'FormDepositAddTeammate',
  components: {AppAvatar},
  props: {
    authors: {type: Array, required: true},
    disabled: {type: Boolean, default: false},
  },
  computed: {
    ...mapState({
      teammates: state => state.profile.company?.teammates,
    }),
    teammatesToAdd () {
      return this.teammates.filter(tm => {
        return !this.authors.find(a => a.account_id === tm.account_id)
      })
    },
  },
  methods: {
    addAuthor (teammate) {
      this.$emit('add', {
        ...newAuthor,
        name: `${teammate.user.first_name} ${teammate.user.last_name}`,
        account_id: teammate.account_id,
        territory: [defaultTerritory],
        contributionWeight: teammate.user.contributionWeight,
      })
    },
  }
}
</script>
